import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1%',
  },
  question: {
    // background: theme?.palette?.background?.grey,
    fontSize: '20px',
    fontWeight: 500,
    color: theme?.palette?.text?.primary,
    lineHeight: 1.1,
    padding: '0.1px 2px',
    borderRadius: '4px',
    margin: 0,
    '& img': {
      maxWidth: '100%',
    },
  },
  marks: {
    background: theme?.palette?.background?.grey,
    padding: '15px 20px',
    borderRadius: '4px',
    height: '62px',
    width: '100%',
  },
  marksCount: {
    display: 'table',
    margin: '0 auto',
    color: theme?.palette?.text?.primary,
    lineHeight: 1.1,
  },
  marksLabel: {
    display: 'table',
    margin: '0 auto',
    color: theme?.palette?.text?.secondary,
    lineHeight: 1.5,
    fontSize: '12px',
    opacity: '80%',
  },
  index: {
    display: 'flex',
    alignItems: 'center',
  },
  choices: {
    padding: '1%',
  },
  choice: {
    minHeight: '6rem',
    maxWidth: '2em',
  },
  testcase: {
    color: 'grey',
  },
  editQuestion: {
    marginBottom: '24px',
  },
  editPaper: {
    padding: '33px 72px',
    [theme.breakpoints.down('xs')]: {
      padding: '24px',
    },
  },
  arrow: {
    '&::before': {
      backgroundColor: 'rgb(0 0 0 / 60%)',
    },
  },
  tooltip: {
    backgroundColor: 'rgb(0 0 0 / 60%)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  addChoiceButton: {
    backgroundColor: 'white',
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
  },
  addTestCaseButton: {
    backgroundColor: theme.palette.background.white,
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    // marginLeft: '7%',
    marginTop: '16px',
    '@media only screen and (max-width:1024px)': {
      // marginLeft: '24px',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  languageText: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  paper: {
    // padding: '24px',
    backgroundColor: 'white',
    borderRadius: '8px',
  },
  selectLanguageWrapper: {
    border: '1px solid #D4D5DB',
    borderRadius: '4px',
    height: '40px',
    padding: '13px 12px 13px 15px',
  },
  selectLanguage: {
    width: '100%',
    outline: 'none',
    border: '0px',
    color: theme.palette.text.primary,
    backgroundColor: `${theme.palette.secondary.contrastText}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  languageBox: {
    display: 'flex',
    height: '34px',
    alignItems: 'center',
    // borderBottom:'4px solid #00B7A4',
    marginRight: '20px',
    marginTop: '10px',
  },
  codeSnippetLanguagesMenu: {
    display: 'flex',
    flex: '1',
    '@media only screen and (max-width:1024px)': {
      flexWrap: 'wrap',
      // flexDirection: 'column',
    },
  },
  funcAndReturnType: {
    display: 'flex',
    '@media only screen and (max-width:1024px)': {
      flexDirection: 'column',
    },
  },
  returnType: {
    '&.Mui-focused': {
      color: '#00B7A4',
      backgroundColor: '#00B7A4',
      borderColor: '#00B7A4',
    },
    width: '90%',
    '@media only screen and (max-width:1024px)': {
      width: '100%',
    },
  },
  addingInfo: {
    fontSize: '12px',
    color: 'grey',
  },
  inputType: {
    width: '500px',
    paddingRight: '80px',
    '@media only screen and (max-width:1024px)': {
      width: '',
    },
  },
  codeLanguageMessage: {
    marginRight: '10px',
    fontWeight: '500',
    paddingTop: '16px',
    fontSize: '16px',
    paddingBottom: '8px',
  },
  codeLanguages: {
    marginRight: '10px',
    fontWeight: '500',
    cursor: 'pointer',
  },
  closeLanguageIcon: {
    width: '16px',
    height: '16px',
  },
  closeIconButton: {
    padding: '0px',
  },
  showLanguagePopper: {
    zIndex: '10',
  },
  selectLanguageContainer: {
    padding: '10px 10px 20px 40px',
  },
  closeButtonInLanguagePopper: {
    padding: 0,
  },
  languageList: {
    marginTop: '25px',
  },
  selectLanguageCheckBox: {
    height: 24,
    width: 24,
    color: 'white',
    backgroundColor: '#00B7A4',
    borderRadius: 2,
    cursor: 'pointer',
  },
  languageCheckIcon: {
    height: 24,
    width: 24,
    color: '#00B7A4',
    backgroundColor: '#00B7A4',
    opacity: '20%',
    borderRadius: 2,
    cursor: 'pointer',
  },
  addCodeLangButton: {
    marginRight: '15px',
    marginTop: '25px',
  },
  codeStubWrapper: {
    marginTop: '15px',
  },
  marksQuestionValidationContainer: {
    padding: '0px 8px 0px 8px',
  },
  marksValidationContainer: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  validationText: {
    fontSize: '12px',
    color: theme?.palette?.inputValidation?.main,
  },
  noSelectedLanguageWrapper: {
    marginTop: '5px',
  },
  codeStubLanguage: {
    color: '#262B4B',
    fontWeight: 600,
    fontSize: '16px',
    marginTop: '5px',
  },
  codeStubLanguageBody: {
    fontSize: '12px',
  },
  marksHeading: {
    width: '100%',
    textAlign: 'center',
    color: '#262A4C',
    fontSize: '12px',
  },
  deleteButton: {
    backgroundColor: theme?.palette?.background?.white,
    border: `1px solid ${theme?.palette?.dangerSecondaryButton?.main}`,
    color: theme?.palette?.dangerSecondaryButton?.main,
    lineHeight: '14px',
    padding: '10px 20px',
    marginRight: '10px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: theme?.palette?.dangerSecondaryButton?.hover,
    },
    [theme.breakpoints.down('xs')]: {
      width: '50px',
    },
  },
  editButton: {
    backgroundColor: theme?.palette?.background?.white,
    border: `1px solid ${theme?.palette?.primaryButton?.main}`,
    color: theme?.palette?.primaryButton?.main,
    lineHeight: '14px',
    padding: '10px 20px',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  questionIndex: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '1.1',
    paddingTop: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  questionHeader: {
    marginBottom: '10px',
  },
  codeSnippetContainer: {
    marginBottom: '16px',
  },
  codeSnippetTitle: {
    height: '22px',
    fontSize: '20px',
  },
  textBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '48px',
    marginTop: '24px',
    color: theme?.palette?.text?.primary,
    '@media only screen and (max-width:1024px)': {
      display: 'none',
    },
    '@media only screen and (min-width:1024px)': {
      // paddingLeft: '10px',
    },
  },
  testCaseNumbers: {
    flex: '5%',
    marginRight: '16px',
  },
  testCaseInputTitle: {
    flex: '45%',
  },
  testCaseOutputTitle: {
    flex: '45%',
    '@media only screen and (min-width:1180px)': {
      paddingLeft: '5px',
    },
    '@media only screen and (min-width:1300px)': {
      paddingLeft: '8px',
    },
    '@media only screen and (min-width:1400px)': {
      paddingLeft: '18px',
    },
    '@media only screen and (min-width:1500px)': {
      paddingLeft: '24px',
    },
  },
  testCaseMarks: {
    flex: '15%',
    marginRight: '8px',
    '@media only screen and (min-width:1200px)': {
      paddingLeft: '14px',
    },
    '@media only screen and (min-width:1300px)': {
      paddingLeft: '32px',
    },
    '@media only screen and (min-width:1430px)': {
      paddingLeft: '45px',
    },
    '@media only screen and (min-width:1550px)': {
      paddingLeft: '75px',
    },
  },
  hiddenCaseTitle: {
    flex: '15%',
    paddingRight: '18px',
    '@media only screen and (min-width:1200px)': {
      paddingLeft: '16px',
    },
    '@media only screen and (min-width:1400px)': {
      paddingLeft: '26px',
    },
    '@media only screen and (min-width:1500px)': {
      paddingLeft: '44px',
    },
    '@media only screen and (min-width:1650px)': {
      paddingLeft: '64px',
    },
  },
  selectedLanguageDisplay: {
    fontWeight: '500',
  },
  noDisplay: {
    '@media only screen and (max-width:1024px)': {
      display: 'none',
    },
  },
  testCaseHeadingForMobile: {
    marginLeft: '24px',
    '@media only screen and (min-width:425px)': {
      marginLeft: '0px',
    },
  },
  testCaseSeparator: {
    marginTop: '24px',
    '@media only screen and (min-width:1024px)': {
      display: 'none',
    },
  },
  selectLanguagePopperBox: {
    border: `1px solid ${theme?.palette?.border?.main}`,
  },
  cancelButton: {
    marginTop: '10px',
    marginRight: '10px',
    '@media only screen and (max-width:460px)': {
      marginRight: '4px',
    },
  },
  codeQuestionMarks: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
  languageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
  },
  languageMenuWrapper: {
    width: '100%',
  },
  languageForm: {
    borderColor: '#00B7A4',
    width: '90%',
    '@media only screen and (max-width:1024px)': {
      width: '100%',
    },
  },
  languageSelect: {
    paddingLeft: '8px',
  },
  inputWrapper: {
    paddingTop: '10px',
    width: '95%',
  },
  inputTypesWrapper: {
    display: 'flex',
    width: '100%',
  },
  inputTypesHeader: {
    paddingTop: '6px',
    fontSize: '16px',
  },
  inputTextFieldWrapper: {
    display: 'flex',
    width: '90%',
    paddingTop: '8px',
    '@media only screen and (max-width:1024px)': {
      width: '100%',
    },
  },
  functionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
  },
  functionTextField: {
    width: '90%',
    '@media only screen and (max-width:1024px)': {
      width: '100%',
    },
  },
  returnTypeWrapper: {
    paddingTop: '10px',
    display: 'flex',
    width: '95%',
    flexDirection: 'column',
  },
  returnTypeHeader: {
    paddingTop: '6px',
    paddingBottom: '8px',
    fontSize: '16px',
  },
  returnTypeFieldWrapper: {
    display: 'flex',
  },
  inputTextField: {
    width: '90%',
    '@media only screen and (max-width:1024px)': {
      width: '100%',
    },
  },
}))

export { useStyles }
